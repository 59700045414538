export interface EnvironmentVariables {
  readonly AWS_ACCESS_KEY_ID: string;
  readonly AWS_SECRET_ACCESS_KEY: string;
  readonly appsyncEndpoint: string;
  readonly awsAccount: string;
  readonly awsRegion: string;
  readonly cloudFrontDomain: string;
  readonly cloudFrontProtocol: string;
  readonly identifyPoolDomain: string;
  readonly identityPoolId: string;
  readonly stage: string;
  readonly userPoolClientId: string;
  readonly userPoolId: string;
}

export const envVariables: EnvironmentVariables = {
  awsAccount: '945258552535',
  identityPoolId: 'us-east-1:42a89b62-69fc-46d4-b0d2-0c1f53895256',
  userPoolClientId: '778nat7657gc0iglqn43s4iqgd',
  userPoolId: 'us-east-1_JKoUwKQN1',
  identifyPoolDomain: 'acstools-devicerename-beta.auth.us-east-1.amazoncognito.com',
  awsRegion: 'us-east-1',
  stage: 'beta',
  cloudFrontProtocol: 'https',
  cloudFrontDomain: 'd2txpjmm25mjdp.cloudfront.net',
  AWS_ACCESS_KEY_ID: '{{AWS_ACCESS_KEY_ID}}',
  AWS_SECRET_ACCESS_KEY: '{{AWS_SECRET_ACCESS_KEY}}',
  appsyncEndpoint: 'api.beta.device-rename.acs.amazon.dev',
};

export const MIDWAY = {
  URL: 'https://midway-auth.amazon.com/SSO',
  LOGOUT_URL: 'https://midway-auth.amazon.com/SSO/redirect',
  SCOPE: 'openid',
  RESPONSE_TYPE: 'id_token',
  SENTRY_HANDLER_VERSION: 'MidwayNginxModule-1.3-1',
};
